import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import KitSelectStyle from './KitSelect.module.css'
import renderImage from '../../source/importImg'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import { fetchVersion } from './FlashFiles'
const tabs = [
    {
        id: 1,
        title: 'Codable Kits',
        defaultDevice: 'Ace',
        content: [
            {
                device: 'Roboki',
                name: 'Roboki',
                src: 'roboki',
                nav: '',
            },
            {
                device: 'Ace1',
                name: 'PeeCee',
                src: 'peeCeeB',
                nav: '',
            },

            {
                device: 'Ace0',
                name: 'PeeCee v0.1',
                src: 'peeCeeA',
                nav: '',
            },
        ],
    },
    {
        id: 2,
        title: 'Animatronics',
        defaultDevice: 'Humanoid',
        content: [
            {
                device: 'Klaw',
                name: 'Klaw-b',
                src: 'klaw',
                nav: '',
            },
            {
                device: 'Hexapod',
                name: 'Crawl-e',
                src: 'crawle',
                nav: '',
            },
            {
                device: 'Humanoid',
                name: 'Zing',
                src: 'zing',
                nav: '',
            },
        ],
    },
    {
        id: 3,
        title: 'Simulation Kits',
        defaultDevice: 'Trons',
        content: [
            {
                name: 'Trons',
                src: 'trons',
                nav: 'https://playtrons.plode.org/',
            },
            {
                name: 'Tronix',
                src: 'tronix',
                nav: 'https://playtronix.plode.org/',
            },
        ],
    },
]
const KitSelection = () => {
    const [isHelp, setHelp] = useState(false)
    const [search, setSearch] = useState('')
    const [activeTab, setActiveTab] = useState(tabs[0].id)
    const [showDialog, setShowDialog] = useState(false)
    const [filteredResults, setFilteredResults] = useState({})
    const [suggestions, setSuggestions] = useState([])
    // const [slideDirection, setSlideDirection] = useState(
    //     sessionStorage.getItem('slideDirection')
    // )
    const optionsToSelect = [
        {
            text: 'YES',
            do: async () => {
                console.log(activeTab)
                if (activeTab == 3) {
                    window.location.href = 'https://playtrons.plode.org/'
                    return
                }
                let connectedDevice = tabs[activeTab - 1].defaultDevice
                console.log(tabs[activeTab])
                sessionStorage.setItem('connectedDevice', connectedDevice)
                let { latestVersion } = await fetchVersion(
                    'https://embedded.plode.org/version-list',
                    connectedDevice == 'Ace' ? 'Playcomputer' : 'Zing',
                    connectedDevice == 'Ace' ? '1.0' : '2.0'
                )
                // // let version = latestVersion.replace(/[a-zA-Z]/g, '')
                // latestVersion = latestVersion.replace('PC', '')
                sessionStorage.setItem(
                    'deviceVersion',
                    latestVersion.slice(0, latestVersion.length - 2)
                )
                history.push('/Selection')
            },
        },
        { text: 'NO', do: () => setShowDialog(false) },
    ]

    const history = useHistory()
    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }
    const gobackUrl = () => {
        // setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        history.push('/')
    }

    const handleAutoConnection = () => {
        history.push('/deviceSelection')
    }
    const handleDefaultConnection = () => {}
    const handleDeviceConnection = (item) => {
        if (['Trons', 'Tronix'].includes(item.name))
            window.open(item.nav, '_blank')
        else {
            sessionStorage.setItem('allowedDevice', item.device)
            history.push('/deviceSelection')
        }
    }
    useEffect(() => {
        console.log('search', filteredResults)
        if (search.length == 0) {
            setActiveTab(1)
            setFilteredResults({})
        } else if (Object.keys(filteredResults).length > 0)
            setActiveTab(filteredResults.id)
    }, [search])
    // console.log(activeTab)
    const handleSearch = (e) => {
        const value = e.target.value

        setSearch(e.target.value)
        if (value) {
            const allSuggestions = [...tabs].flatMap((tab) =>
                tab.content.map((item) => ({
                    name: item.name,
                    src: item.src,
                    nav: item.nav,
                    title: tab.title,
                    id: tab.id,
                }))
            )
            const filteredSuggestions = allSuggestions.filter((suggestion) =>
                suggestion.name.toLowerCase().includes(value.toLowerCase())
            )
            console.log(filteredSuggestions)
            setSuggestions(filteredSuggestions)
        } else {
            setSuggestions([])
        }
    }
    const handleSuggestion = (suggestion) => {
        setSearch(suggestion.name)
        setFilteredResults(suggestion)
        setSuggestions([])
    }

    return (
        <>
            <DialogModal
                show={showDialog}
                text="Do you want to continue without your device being connected ?"
                showCloseBtn={false}
                handleDialogModalClose={() => setShowDialog(false)}
                optionsToSelect={optionsToSelect}
            />
            <div className={KitSelectStyle.container}>
                <div className={KitSelectStyle.Connect_Header}>
                    {isHelp ? (
                        <div>
                            <img
                                className={KitSelectStyle.back_btn}
                                style={{ zIndex: '-1' }}
                                src={renderImage('backBtn')}
                                onClick={gobackUrl}
                            ></img>{' '}
                        </div>
                    ) : (
                        <div className="">
                            <img
                                className={KitSelectStyle.back_btn}
                                style={{ zIndex: '1000' }}
                                src={renderImage('backBtn')}
                                onClick={gobackUrl}
                            ></img>{' '}
                        </div>
                    )}
                    <p className={KitSelectStyle.text}>Select Your Device</p>
                    <div>
                        {isHelp == false && (
                            <div>
                                <div className={KitSelectStyle.searchInput}>
                                    <input
                                        type="text"
                                        name="search"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                    {Object.keys(filteredResults).length > 0 ? (
                                        <div
                                            className={KitSelectStyle.binIcon}
                                            onClick={() => setSearch('')}
                                        >
                                            <img
                                                src="https://cdn-icons-png.flaticon.com/128/2976/2976286.png"
                                                alt="bin"
                                            />
                                            {/* <img
                                                    src="https://cdn-icons-png.flaticon.com/128/3395/3395538.png"
                                                    alt="search"
                                                /> */}
                                            {/* </div> */}
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                KitSelectStyle.searchIcon
                                            }
                                        >
                                            <img
                                                src={renderImage('searchIcon')}
                                                alt="search"
                                            />
                                        </div>
                                    )}
                                    {suggestions.length > 0 && (
                                        <ul
                                            className={
                                                KitSelectStyle.suggestions
                                            }
                                        >
                                            {suggestions.map(
                                                (suggestion, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={() =>
                                                            handleSuggestion(
                                                                suggestion
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                KitSelectStyle.suggestion_title
                                                            }
                                                        >
                                                            {suggestion.title}
                                                        </span>{' '}
                                                        /
                                                        <span
                                                            className={
                                                                KitSelectStyle.suggestion_name
                                                            }
                                                        >
                                                            {suggestion.name}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </div>
                                <div className="header_button">
                                    <img
                                        className={KitSelectStyle.helpBtn}
                                        src={renderImage('helpBtnInActive')}
                                        onClick={handleHelpBtn}
                                    ></img>
                                    <span className="tooltiptext">
                                        {' '}
                                        <p>Help</p>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={KitSelectStyle.Connect_Body}>
                    <div className={KitSelectStyle.tabs}>
                        <p className={KitSelectStyle.header}>Device</p>
                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                className={`${KitSelectStyle.tab} ${
                                    tab.id === activeTab
                                        ? KitSelectStyle.active
                                        : ''
                                }`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                <p className={KitSelectStyle.title}>
                                    {tab.title}
                                </p>
                                <div className={KitSelectStyle.arrow}>
                                    <img
                                        src={renderImage('arrowRight')}
                                        alt="arrowRight"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={KitSelectStyle.content}>
                        {filteredResults &&
                        Object.keys(filteredResults).length > 0 ? (
                            <div className={KitSelectStyle.tabContent}>
                                <div
                                    onClick={() =>
                                        handleDeviceConnection(
                                            filteredResults.device
                                        )
                                    }
                                    className={KitSelectStyle.card}
                                >
                                    <div className={KitSelectStyle.cardImg}>
                                        <img
                                            src={renderImage(
                                                filteredResults.src
                                            )}
                                            alt={filteredResults.src}
                                        />
                                    </div>
                                    <p className={KitSelectStyle.cardName}>
                                        {filteredResults.name}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <>
                                {tabs.map(
                                    (tab, index) =>
                                        tab.id === activeTab && (
                                            <div
                                                key={index}
                                                className={
                                                    KitSelectStyle.tabContent
                                                }
                                            >
                                                {tab.content.map(
                                                    (item, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() =>
                                                                handleDeviceConnection(
                                                                    item
                                                                )
                                                            }
                                                            className={
                                                                KitSelectStyle.card
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    KitSelectStyle.cardImg
                                                                }
                                                            >
                                                                <img
                                                                    src={renderImage(
                                                                        item.src
                                                                    )}
                                                                    alt={
                                                                        item.src
                                                                    }
                                                                />
                                                            </div>
                                                            <p
                                                                className={
                                                                    KitSelectStyle.cardName
                                                                }
                                                            >
                                                                {item.name}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className={KitSelectStyle.Connect_Footer}>
                    {/* <div
                        className={KitSelectStyle.navigate}
                        onClick={() => setShowDialog(true)}
                    >
                        <img
                            src={renderImage('doubleArrow')}
                            alt="arrowRight"
                        />
                    </div> */}
                    <div
                    // className={KitSelectStyle.auto}
                    >
                        <img
                            style={{ width: '90px', cursor: 'pointer' }}
                            src={renderImage('auto')}
                            alt="arrowRight"
                            onClick={handleAutoConnection}
                        />
                    </div>
                </div>
            </div>

            {isHelp && (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '47%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                            width: '65vw',
                        }}
                    >
                        <div className={KitSelectStyle.web_button}>
                            <img
                                className={KitSelectStyle.Cross}
                                src={renderImage('clos')}
                                onClick={handleHelpBtn}
                            ></img>
                        </div>
                        <div>
                            <img
                                src={
                                    sessionStorage.getItem(
                                        'connectedDevice'
                                    ) === 'Humanoid'
                                        ? renderImage('ZingConnect')
                                        : renderImage('Connect')
                                }
                                style={{
                                    width: '100%',
                                }}
                            ></img>
                        </div>
                        <div className={KitSelectStyle.instr}>
                            <p className={KitSelectStyle.instr_P}>
                                {' '}
                                Help Button will explain all the features and
                                functionality of the entire app for each screen{' '}
                            </p>
                        </div>
                        <div className={KitSelectStyle.instr2}>
                            <p className={KitSelectStyle.instr2_P}>
                                Manual scan will list all the Playcomputer
                                devices if multiple devices are ON
                            </p>
                        </div>
                        <div className={KitSelectStyle.instr3}>
                            <p className={KitSelectStyle.instr3_P}>
                                Skip button will skip the scanning and
                                connecting over bluetooth to playcomputer.If you
                                skip you will not be able to send data to
                                playcomputer.{' '}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default KitSelection
