/**
 * This module exports a draggable Component which is drawn in Workspace
 * @module components/assembly/Component
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DragSource } from 'react-dnd-old'
import ItemTypes from './ItemTypes'
import DraggingInfo from './DraggingInfo'
import { getEmptyImage } from 'react-dnd-html5-backend-old'
import renderCompImg from '../../source/Comp_Img'
import removeSensorProgramData from '../../utils/removeSensorProgramData'
import removeHumanoidLocalProgramData from '../../utils/removeHumanoidLocalProgramData'

var clickStartTimestamp = undefined // Posible as only one component can be clicked at a particular time

const style = {
    position: 'absolute',
    cursor: 'move',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    zIndex: 2,
}

const isPcVersion1 =
    sessionStorage.getItem('deviceVersion')?.slice(0, 5) == '1.0.0' &&
    sessionStorage.getItem('deviceVersion') === 'Ace'
let isDeviceVersion12 =
    (['1', '2'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    ) &&
        ['Ace', 'Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )) ||
    ['Roboki', 'Klaw'].includes(sessionStorage.getItem('connectedDevice'))

const componentSource = {
    beginDrag(props) {
        DraggingInfo.isDragging = true
        const { type, index, left, top, connectedTo } = props
        // console.log("component@@", props);
        sessionStorage.setItem('dragingItem', props.type)
        props.removeConnection({ type, index })
        try {
            document.getElementById(type + props.index).style.opacity = 0
        } catch (E) {}
        DraggingInfo.newComponentPort = connectedTo
        DraggingInfo.draggingComponentOld = { type, index }
        return { type, index, left, top, connectedTo }
    },
    endDrag() {
        clickStartTimestamp = undefined
        DraggingInfo.isDragging = false
        DraggingInfo.draggingComponentOld = null
        DraggingInfo.newComponentPort = null
    },
}
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}
// var Component = React.createClass({
class Component1 extends Component {
    constructor(props) {
        super(props)
        // this.removeComponent = this.removeComponent.bind(this);
        // () => this.typeCheck(this) = () => this.typeCheck(this)(this)
    }

    // componentDidMount = () => {
    //     console.log('COMPONENTDID')
    //     // Use empty image as a drag preview so browsers don't draw it
    //     // and we can draw whatever we want on the custom drag layer instead.
    //     this.props.connectDragPreview(getEmptyImage(), {
    //         // IE fallback: specify that we'd rather screenshot the node
    //         // when it already knows it's being dragged so we can hide it with CSS.
    //         //   captureDraggingState: true,
    //     })
    //     document.addEventListener('mouseleave', this.mouseOutOfBound)
    // }
    componentDidUpdate() {
        this.props.connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            //   captureDraggingState: true,
        })
        document.addEventListener('mouseleave', this.mouseOutOfBound)
    }
    componentWillUnmount = () => {
        document.removeEventListener('mouseleave', this.mouseOutOfBound)
    }

    mouseOutOfBound = (event) => {
        if (event.clientX < 0 && this.props.isDragging) {
            this.removeComponent()
        }
    }

    checkForLongPress = () => {
        if (!clickStartTimestamp) return
        else if (Date.now() - clickStartTimestamp > 1000) {
            this.removeComponent()
        }
        clickStartTimestamp = undefined

        //isSave = false when delete connections with double clicks
        sessionStorage.setItem('isSave', JSON.stringify(false))
    }

    removeComponent = () => {
        const { type, index } = this.props

        var prev_data = this.props.appState

        var port = this.props.connectedTo
        console.log('FFFFFFFFFFFFF', port)
        prev_data.logic.program = removeSensorProgramData(
            prev_data.logic.program,
            port,
            type
        )

        removeHumanoidLocalProgramData(port)
        const isArmShield =
            sessionStorage.getItem('armShield') == 'true' || false
        const isPlayShield = sessionStorage.getItem('shield') == 'true' || false
        const isPeeCeeATR =
            sessionStorage.getItem('peeCeeATR') == 'true' || false

        if (
            ['Ace', 'Roboki'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        ) {
            if (type == 'pc_motor_driver') {
                if (port == 'A' || port == 'C') {
                    prev_data.assembly.PortConnections['A'] = null
                    prev_data.assembly.PortConnections['C'] = null
                }
                if (port == 'B' || port == 'D') {
                    prev_data.assembly.PortConnections['B'] = null
                    prev_data.assembly.PortConnections['D'] = null
                }
            } else if (type == 'stepper_motor') {
                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.B !== null
                ) {
                    if (
                        JSON.parse(sessionStorage.getItem('assembly'))
                            .PortConnections.B.type == 'pc_motor_driver'
                    ) {
                        if (port == 'STPM') {
                            prev_data.assembly.PortConnections['B1'] = null
                            prev_data.assembly.PortConnections['B2'] = null
                            prev_data.assembly.PortConnections['D2'] = null
                            prev_data.assembly.PortConnections['D1'] = null
                        }
                    }
                }

                if (
                    JSON.parse(sessionStorage.getItem('assembly'))
                        .PortConnections.A !== null
                ) {
                    if (
                        JSON.parse(sessionStorage.getItem('assembly'))
                            .PortConnections.A.type == 'pc_motor_driver'
                    ) {
                        if (port == 'STPM') {
                            prev_data.assembly.PortConnections['A1'] = null
                            prev_data.assembly.PortConnections['A2'] = null
                            prev_data.assembly.PortConnections['C2'] = null
                            prev_data.assembly.PortConnections['C1'] = null
                        }
                    }
                }
            } else if (
                type != 'servo_motor' ||
                type != 'servo_motor_270' ||
                type != 'ultrasonic_sensor'
            ) {
                if (port == 'A') {
                    prev_data.assembly.PortConnections['A'] = null
                    prev_data.assembly.PortConnections['A1'] = null
                    prev_data.assembly.PortConnections['A2'] = null
                }
                if (port == 'B') {
                    prev_data.assembly.PortConnections['B'] = null
                    prev_data.assembly.PortConnections['B1'] = null
                    prev_data.assembly.PortConnections['B2'] = null
                }
                if (port == 'C') {
                    prev_data.assembly.PortConnections['C'] = null
                    prev_data.assembly.PortConnections['C1'] = null
                    prev_data.assembly.PortConnections['C2'] = null
                }
                if (port == 'D') {
                    prev_data.assembly.PortConnections['D'] = null
                    prev_data.assembly.PortConnections['D1'] = null
                    prev_data.assembly.PortConnections['D2'] = null
                }
                if (port == 'E') {
                    prev_data.assembly.PortConnections['E'] = null
                    prev_data.assembly.PortConnections['E1'] = null
                    prev_data.assembly.PortConnections['E2'] = null
                }
                if (port == 'F') {
                    prev_data.assembly.PortConnections['F'] = null
                    prev_data.assembly.PortConnections['F1'] = null
                    prev_data.assembly.PortConnections['F2'] = null
                }
            } else {
                console.log('HHHHHHHHHHHH', port)
                if (port == 'A') {
                    prev_data.assembly.PortConnections['A1'] = null
                    prev_data.assembly.PortConnections['A2'] = null
                } else if (port == 'B') {
                    prev_data.assembly.PortConnections['B1'] = null
                    prev_data.assembly.PortConnections['B2'] = null
                } else if (port == 'C') {
                    prev_data.assembly.PortConnections['C1'] = null
                    prev_data.assembly.PortConnections['C2'] = null
                } else if (port == 'D') {
                    prev_data.assembly.PortConnections['D1'] = null
                    prev_data.assembly.PortConnections['D2'] = null
                } else {
                    prev_data.assembly.PortConnections[port] = null
                }
            }
        } else {
            prev_data.assembly.PortConnections[port] = null
        }

        if (port == 'M1' && isPlayShield) {
            prev_data.assembly.PortConnections['M1'] = null
            prev_data.assembly.PortConnections['M2'] = null
        } else if (port == 'M3' && isPlayShield) {
            prev_data.assembly.PortConnections['M3'] = null
            prev_data.assembly.PortConnections['M4'] = null
        } else if (port == 'MOTOR1' && isArmShield) {
            prev_data.assembly.PortConnections['MOTOR1'] = null
            prev_data.assembly.PortConnections['M2'] = null
            prev_data.assembly.PortConnections['M3'] = null
        }

        if (port == 'A1' || port == 'P0') {
            prev_data.assembly.PortConnections['A1'] = null
            prev_data.assembly.PortConnections['P0'] = null
        } else if (port == 'B1' || port == 'P1') {
            prev_data.assembly.PortConnections['B1'] = null
            prev_data.assembly.PortConnections['P1'] = null
        } else if (port == 'C1' || port == 'P2') {
            prev_data.assembly.PortConnections['C1'] = null
            prev_data.assembly.PortConnections['P2'] = null
        }

        if (port == 'S1' && !isPcVersion1) {
            prev_data.assembly.PortConnections['A1'] = null
            prev_data.assembly.PortConnections['S1'] = null
        } else if (port == 'S2' && !isPcVersion1) {
            prev_data.assembly.PortConnections['C1'] = null
            prev_data.assembly.PortConnections['S2'] = null
        } else if (port == 'S1' && isPcVersion1) {
            prev_data.assembly.PortConnections['S1'] = null
        } else if (port == 'S2' && isPcVersion1) {
            prev_data.assembly.PortConnections['S2'] = null
        } else if (port == 'M1' && isPeeCeeATR) {
            prev_data.assembly.PortConnections['M1'] = null
            prev_data.assembly.PortConnections['M2'] = null
        } else if (port == 'M3' && isPeeCeeATR) {
            prev_data.assembly.PortConnections['M3'] = null
            prev_data.assembly.PortConnections['M4'] = null
        } else if (port == 'G' && isPeeCeeATR && !isPcVersion1) {
            prev_data.assembly.PortConnections['G'] = null
            prev_data.assembly.PortConnections['C2'] = null
            prev_data.assembly.PortConnections['F1'] = null
        } else if (port == 'H' && isPeeCeeATR && !isPcVersion1) {
            prev_data.assembly.PortConnections['H'] = null
            prev_data.assembly.PortConnections['A2'] = null
            prev_data.assembly.PortConnections['F2'] = null
        } else if (port == 'G' && isPeeCeeATR && isPcVersion1) {
            prev_data.assembly.PortConnections['G'] = null
            prev_data.assembly.PortConnections['E1'] = null
            prev_data.assembly.PortConnections['E2'] = null
        } else if (port == 'H' && isPeeCeeATR && isPcVersion1) {
            prev_data.assembly.PortConnections['H'] = null
            prev_data.assembly.PortConnections['F1'] = null
            prev_data.assembly.PortConnections['F2'] = null
        }

        this.props.removeFromWorkspace({ type, index, port })

        ////////// manually removing ports while component dropped out of bounds //////
        if (this.props.type == 'dual_splitter') {
            if (this.props.prop.PortConnections['A'] == null) {
                prev_data.assembly.PortConnections['A1'] = null
                prev_data.assembly.PortConnections['A2'] = null
            }
            if (this.props.prop.PortConnections['B'] == null) {
                prev_data.assembly.PortConnections['B1'] = null
                prev_data.assembly.PortConnections['B2'] = null
            }
            if (this.props.prop.PortConnections['C'] == null) {
                prev_data.assembly.PortConnections['C1'] = null
                prev_data.assembly.PortConnections['C2'] = null
            }
            if (this.props.prop.PortConnections['D'] == null) {
                prev_data.assembly.PortConnections['D1'] = null
                prev_data.assembly.PortConnections['D2'] = null
            }
        }
        ////////////////////////////////////////////////////////////////////////////////

        this.props.appState.PortConnections(prev_data.assembly.PortConnections)
        var { logic } = this.props.appState
        this.props.appState.logicComponent(logic)
    }

    typeCheck = () => {
        this.removeComponent()
    }
    render() {
        if (this.props.type == 'rotatory') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left - 10
            top = top - 7

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-1.1%',
                            marginLeft: '1.1%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '0.1%',
                            marginLeft: '6.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'pot') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            // laxmi
            left = isDeviceVersion12 ? left - 2 : (left = left + 2)
            top = top - 19

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.5%',
                            marginLeft: '-1.1%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'linear_pot') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left - 10
            top = top + 16

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.5%',
                            marginLeft: '-3.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'rain_sensor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = isDeviceVersion12 ? left - 3 : left
            top = top - 21

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '3.5%',
                            marginLeft: '1%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '4%',
                            marginLeft: '8%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'humidity') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left + 4
            top = top - 9

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-1.1%',
                            marginLeft: '1.1%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    {/* <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '0.1%',
                            marginLeft: '6.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p> */}
                </div>
            )
        } else if (this.props.type == 'temperature_sensor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = left + 3
            top = top - 18

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.5%',
                            marginLeft: '-0.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'gas') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = left + 2
            top = top - 21

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.1%',
                            marginLeft: '-1.1%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'distance_sensor') {
            let connectedToPort = this.props.connectedTo
            console.log(this.props.connectedTo, '<<<<:<:<:<:<:')

            console.log(this.props.rangeA1, '<<<<:<:<:<:<:')

            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = isDeviceVersion12 ? left - 4 : left - 2
            top = top - 22

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />

                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '3%',
                            marginLeft: '-1%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'RGB') {
            let connectedToPort = this.props.connectedTo
            console.log(this.props.connectedTo, '<<<<:<:<:<:<:')

            console.log(this.props.rangeA1, '<<<<:<:<:<:<:')

            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left + 22
            top = top + 35

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                </div>
            )
        } else if (this.props.type == 'mp3') {
            let connectedToPort = this.props.connectedTo
            console.log(this.props.connectedTo, '<<<<:<:<:<:<:')

            console.log(this.props.rangeA1, '<<<<:<:<:<:<:')

            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left + 13
            top = top + 14

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                </div>
            )
        } else if (this.props.type == 'tact_switch') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            //CONNECTION WIRE
            var height = 100 * scale
            var width = 100 * scale
            left = left + 4
            top = top - 13

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />

                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.5%',
                            marginLeft: '0.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'tact_switch_2c') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            //CONNECTION WIRE
            var height = 100 * scale
            var width = 100 * scale
            top = isDeviceVersion12 ? top - 10 : top - 5
            left = isDeviceVersion12 ? left - 1 : left - 5

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,
                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />

                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.5%',
                            marginLeft: '0.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.1%',
                            marginLeft: '6%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.two} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'dip_switch') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            //CONNECTION WIRE
            var height = 110 * scale
            var width = 110 * scale
            top = isDeviceVersion12 ? top - 20 : top - 19
            left = isDeviceVersion12 ? left - 2 : left + 3

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />

                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-1.2%',
                            marginLeft: '3%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.7%',
                            marginLeft: '6.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.two} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'metal_detector') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            //CONNECTION WIRE
            var height = 150 * scale
            var width = 150 * scale
            top = top - 60
            left = left - 11

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />

                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.5%',
                            marginLeft: '0.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.1%',
                            marginLeft: '6%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    ></p>
                </div>
            )
        } else if (this.props.type == 'dual_splitter') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = isDeviceVersion12 ? (5 + 140) * scale : 140 * scale
            var width = isDeviceVersion12 ? (20 + 130) * scale : 130 * scale
            top = top - 22
            left = left - 21

            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        backgroundImage: `url(${renderCompImg(type)})`,

                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (
            this.props.type == 'dc_motor' ||
            this.props.type == 'dynamex_motor'
        ) {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 120 * scale
            var width = 120 * scale
            if (this.props.type == 'dynamex_motor') {
                top = top + 10
                left = left + 10
            } else {
                top = top - 7
                left = left - 10
            }

            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        backgroundImage: `url(${renderCompImg(type)})`,

                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (this.props.type == 'mini_geared_motor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 120 * scale
            var width = 120 * scale
            left = left - 15
            top = top - 12

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                </div>
            )
        } else if (this.props.type == 'geared_motor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 120 * scale
            var width = 120 * scale
            left = left - 7
            top = top - 8

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                </div>
            )
        } else if (this.props.type == 'joystick') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 120 * scale
            var width = 120 * scale
            left = isDeviceVersion12 ? left - 15 : left - 7
            top = isDeviceVersion12 ? top - 10 : top - 29

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '-0.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '7%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.two} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'extender') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = left
            top = top - 19

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '-0.5%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.one} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '7%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.two} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'ultrasonic_sensor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = left - 10
            top = top + 12

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-0.7%',
                            marginLeft: '3%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.temp} */}
                        {this.props.connectedTo == 'A'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'C'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'D'
                            ? this.props.rangeD1
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'light_sensor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            left = isDeviceVersion12 ? left + 1 : left + 3
            top = top - 13

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '3%',
                            marginLeft: '-2%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                    {/* <p
             style={{
               position: "absolute",
               top,
               marginTop: "3%",
               marginLeft: "6.5%",
               left,
               zIndex: "3",
               color: "#707070",
               fontSize: "20px",
             }}
           >
             {this.props.connectedTo == "A"
               ? this.props.rangeA2
               : this.props.connectedTo == "B"
               ? this.props.gas
               : this.props.connectedTo == "C"
               ? this.props.two
               : null}
           </p> */}
                </div>
            )
        } else if (this.props.type == 'touch_sensor') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 110 * scale
            var width = 110 * scale
            left = left + 2
            top = top - 19

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '-1%',
                            marginLeft: '0%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo == 'A' ||
                        this.props.connectedTo == 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo == 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo == 'B' ||
                              this.props.connectedTo == 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo == 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo == 'C' ||
                              this.props.connectedTo == 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo == 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo == 'D' ||
                              this.props.connectedTo == 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo == 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo == 'E' ||
                              this.props.connectedTo == 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo == 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo == 'F' ||
                              this.props.connectedTo == 'F1'
                            ? this.props.rangeF1
                            : this.props.connectedTo == 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'dual_switch') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 120 * scale
            var width = 120 * scale
            top = isDeviceVersion12 ? top - 30 : top - 27
            left = isDeviceVersion12 ? left - 5 : left - 2

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '3.5%',
                            marginLeft: '1%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '4%',
                            marginLeft: '8%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'octa_splitter') {
            let {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 125 * scale
            var width = 125 * scale

            if (height == 115 || height == 95.83333333333334) {
            } else {
                top = top - 40
                left = left - 10
            }
            // var top1=100-top;
            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (this.props.type == 'temp_gas') {
            const {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '-2%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.temp} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2%',
                            marginLeft: '7%',
                            left,
                            zIndex: '3',
                            fontSize: '20px',
                            color: '#707070',
                        }}
                    >
                        {/* {this.props.gas} */}
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (this.props.type == 'temp_dew') {
            const {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left,
                            top,
                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,

                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.5%',
                            marginLeft: '-2.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A1'
                            ? this.props.rangeA1
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B1'
                            ? this.props.rangeB1
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C1'
                            ? this.props.rangeC1
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D1'
                            ? this.props.rangeD1
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E1'
                            ? this.props.rangeE1
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F1'
                            ? this.props.rangeF1
                            : null}
                    </p>
                    <p
                        style={{
                            position: 'absolute',
                            top,
                            marginTop: '2.5%',
                            marginLeft: '6.5%',
                            left,
                            zIndex: '3',
                            color: '#707070',
                            fontSize: '20px',
                        }}
                    >
                        {this.props.connectedTo === 'A' ||
                        this.props.connectedTo === 'A2'
                            ? this.props.rangeA2
                            : this.props.connectedTo === 'B' ||
                              this.props.connectedTo === 'B2'
                            ? this.props.rangeB2
                            : this.props.connectedTo === 'C' ||
                              this.props.connectedTo === 'C2'
                            ? this.props.rangeC2
                            : this.props.connectedTo === 'D' ||
                              this.props.connectedTo === 'D2'
                            ? this.props.rangeD2
                            : this.props.connectedTo === 'E' ||
                              this.props.connectedTo === 'E2'
                            ? this.props.rangeE2
                            : this.props.connectedTo === 'F' ||
                              this.props.connectedTo === 'F2'
                            ? this.props.rangeF2
                            : null}
                    </p>
                </div>
            )
        } else if (
            this.props.type == 'servo_motor' ||
            this.props.type == 'servo_motor_270'
        ) {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            top = top + 10
            left = left

            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        backgroundImage: `url(${renderCompImg(type)})`,

                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (this.props.type == 'servo_motor_360') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale
            top = top + 10
            left = left

            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        backgroundImage: `url(${renderCompImg(type)})`,

                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (this.props.type == 'OLED') {
            var {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 105 * scale
            var width = 105 * scale
            top = top + 6
            left = left - 10

            return connectDragSource(
                <div
                    id={this.props.type + this.props.index}
                    style={{
                        ...style,
                        left,
                        top,
                        // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                        backgroundImage: `url(${renderCompImg(type)})`,

                        height,
                        width,
                    }}
                    onMouseDown={() => {
                        clickStartTimestamp = Date.now()
                    }}
                    onMouseUp={this.checkForLongPress}
                    onDoubleClick={() => this.typeCheck(this)}
                />
            )
        } else if (
            this.props.type == 'play_shield' ||
            this.props.type == 'play_shield_v1'
        ) {
            sessionStorage.setItem('shield', 'true')
            this.props.setShield(true)

            return null
        } else if (this.props.type == 'arm_shield') {
            sessionStorage.setItem('armShield', 'true')
            this.props.setArmShield(true)
            return null
        } else if (this.props.type == 'peeCee_ATR') {
            sessionStorage.setItem('peeCeeATR', 'true')
            this.props.setPeeCeeATR(true)
            return null
        } else {
            const {
                type,
                left,
                top,
                scale,
                connectDragSource,
                isDragging,
                children,
            } = this.props
            if (isDragging) {
                return null
            }

            var height = 100 * scale
            var width = 100 * scale

            return connectDragSource(
                <div>
                    <div
                        id={this.props.type + this.props.index}
                        style={{
                            ...style,
                            left: left + 3,
                            top: top - 12,

                            // backgroundImage: "url(images/oldImages/component_" + type + ".png)",
                            backgroundImage: `url(${renderCompImg(type)})`,
                            height,
                            width,
                        }}
                        onMouseDown={() => {
                            clickStartTimestamp = Date.now()
                        }}
                        onMouseUp={this.checkForLongPress}
                        onDoubleClick={() => this.typeCheck(this)}
                    />
                </div>
            )
        }
    }

    // });
}

export default DragSource(
    ItemTypes.COMPONENT,
    componentSource,
    collect
)(Component1)
