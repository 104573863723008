import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import renderPrgImage from '../../../source/programImg'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
import MainHeader from '../../ReusableComponents/Header/MainHeader'
import VisualPrgm from '../../ReusableComponents/PrgmSlider/VisualPrgm/VisualPrgm'
import PrgmSelection from '../../ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'
import ZingPrgmSelection from '../../ReusableComponents/PrgmSlider/ZingPrgmSelection/ZingPrgmSelection'
import PortConnections from '../../Assembly/PortConnections'
import components from '../../concept/data'
import { retrieveAssemblyData } from '../../../utils/retrieveData'
import cacheAssets from '../../../utils/cacheAssets'
import renderCompImg from '../../../source/Comp_Img'
import renderImage from '../../../source/importImg'
import ViewActions from '../CreateAction/ViewActions'

const getUrl = {
    PeeCeeAlpha: '/selectScreen/InternalAccessories',
    PeeCeeBeta: '/v1/selectScreen/InternalAccessories',
    Hexapod: '/code/InternalAccessories',
    Humanoid: '/code/InternalAccessories',
    // Klaw: '/selectScreen/ExternalAccessories',
    Klaw: '/code/InternalAccessories',
    Roboki: '/v1/selectScreen/InternalAccessories',
}
let deviceVersion = sessionStorage.getItem('deviceVersion') || ''

// const getUrl = () => {
//     const version = sessionStorage.getItem('deviceVersion')
//     if (version?.startsWith('1')) {
//         return '/v1/selectScreen/InternalAccessories'
//     } else {
//         return '/selectScreen/InternalAccessories'
//     }
// }

// let ACE_MENU_OPTIONS = [
//     {
//         name: 'New Project',
//         imgSrc: 'newfilegroupbutton',
//         link: getUrl(),
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'Open Project',
//         imgSrc: 'yourprojectsgroupbutton',
//         link: '/comingSoon',
//         imgStyle: { width: '19rem' },
//     },
// ]

// const HUMANOID_MENU_OPTIONS = [
//     {
//         name: 'New Project',
//         imgSrc: 'newfilegroupbutton',
//         link: '/code/InternalAccessories',
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'Open Project',
//         imgSrc: 'yourprojectsgroupbutton',
//         link: '/comingSoon',
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'New Action',
//         imgSrc: 'newactiongroupbutton',
//         link: '/code/project/action/select',
//         imgStyle: {
//             width: '19rem',

//             marginTop: '20px',
//             scale: '1.05',
//         },
//     },
//     {
//         name: 'Open Action',
//         imgSrc: 'youractiongroupbutton',
//         link: '/code/project/actions',
//         imgStyle: {
//             width: '19rem',
//             marginTop: '20px',
//             scale: '1.05',
//         },
//     },
// ]

const container = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22rem',
    width: '100vw',
    // paddingTop: '180px',
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
}

const menuText = {
    position: 'relative',
    top: '-8.5rem',
    left: '6rem',
    fontSize: '1.3rem',
    cursor: 'pointer',
    width: '50%',
}

function Project(props) {
    const DEVICE_MENU_OPTIONS = {
        Ace: [
            {
                name: 'New Project',
                imgSrc: 'newfilegroupbutton',
                link: getUrl[
                    deviceVersion.startsWith('1') ? 'PeeCeeBeta' : 'PeeCeeAlpha'
                ],
                imgStyle: { width: '19rem' },
            },
            {
                name: 'Open Project',
                imgSrc: 'yourprojectsgroupbutton',
                link: '/comingSoon',
                imgStyle: { width: '19rem' },
            },
        ],

        Hexapod: [
            {
                name: 'New Project',
                imgSrc: 'newfilegroupbutton',
                link: '/code/InternalAccessories',
                imgStyle: { width: '19rem' },
            },
            {
                name: 'Open Project',
                imgSrc: 'yourprojectsgroupbutton',
                link: '/comingSoon',
                imgStyle: { width: '19rem' },
            },
            {
                name: 'New Action',
                imgSrc: 'newactiongroupbutton',
                link: '/code/project/action/select',
                imgStyle: {
                    width: '19rem',
                    marginTop: '20px',
                    scale: '1.05',
                },
            },
            {
                name: 'Open Action',
                imgSrc: 'youractiongroupbutton',
                link: '/code/project/actions',
                imgStyle: {
                    width: '19rem',
                    marginTop: '20px',
                    scale: '1.05',
                },
            },
        ],
        Humanoid: [
            {
                name: 'New Project',
                imgSrc: 'newfilegroupbutton',
                link: '/code/InternalAccessories',
                imgStyle: { width: '19rem' },
            },
            {
                name: 'Open Project',
                imgSrc: 'yourprojectsgroupbutton',
                link: '/comingSoon',
                imgStyle: { width: '19rem' },
            },
            {
                name: 'New Action',
                imgSrc: 'newactiongroupbutton',
                link: '/code/project/action/select',
                imgStyle: {
                    width: '19rem',

                    marginTop: '20px',
                    scale: '1.05',
                },
            },
            {
                name: 'Open Action',
                imgSrc: 'youractiongroupbutton',
                link: '/code/project/actions',
                imgStyle: {
                    width: '19rem',
                    marginTop: '20px',
                    scale: '1.05',
                },
            },
        ],
        Klaw: [
            {
                name: 'New Project',
                imgSrc: 'newfilegroupbutton',
                link: '/code/InternalAccessories',
                imgStyle: { width: '19rem' },
            },
            {
                name: 'Open Project',
                imgSrc: 'yourprojectsgroupbutton',
                link: '/comingSoon',
                imgStyle: { width: '19rem' },
            },
            // {
            //     name: 'New Action',
            //     imgSrc: 'newactiongroupbutton',
            //     link: '/code/project/action/select',
            //     imgStyle: {
            //         width: '19rem',

            //         marginTop: '20px',
            //         scale: '1.05',
            //     },
            // },
            // {
            //     name: 'Open Action',
            //     imgSrc: 'youractiongroupbutton',
            //     link: '/code/project/actions',
            //     imgStyle: {
            //         width: '19rem',
            //         marginTop: '20px',
            //         scale: '1.05',
            //     },
            // },
        ],
        Roboki: [
            {
                name: 'New Project',
                imgSrc: 'newfilegroupbutton',
                link: getUrl['Roboki'],
                imgStyle: { width: '19rem' },
            },
            {
                name: 'Open Project',
                imgSrc: 'yourprojectsgroupbutton',
                link: '/comingSoon',
                imgStyle: { width: '19rem' },
            },
        ],
    }

    const history = useHistory()
    let programMode = sessionStorage.getItem('programMode')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const version = sessionStorage.getItem('deviceVersion')
    const [isRecoverData, setIsRecoverData] = useState({
        recoveryDataPresent: false,
        link: '/code/project/',
    })
    const [isRetrieveData, setRetrieveData] = useState(false)
    const [isCondProps, setCondProps] = useState({})
    const [isText, setText] = useState('')
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [showActionModal, setShowActionModal] = useState(false)
    const [retrieveActions, setRetrieveActions] = useState(false)

    let MENU_OPTIONS = false
    MENU_OPTIONS = DEVICE_MENU_OPTIONS[connectedDevice]

    // useEffect(() => {
    //     ACE_MENU_OPTIONS = [
    //         {
    //             name: 'New Project',
    //             imgSrc: 'newfilegroupbutton',
    //             link: getUrl(),
    //             imgStyle: { width: '19rem' },
    //         },
    //         {
    //             name: 'Open Project',
    //             imgSrc: 'yourprojectsgroupbutton',
    //             link: '/comingSoon',
    //             imgStyle: { width: '19rem' },
    //         },
    //     ]
    // }, [props.device])
    useEffect(() => {
        var concept = { counter: [], componentProps: {} }
        var assembly = {
            components: components,
            PortConnections: PortConnections,
            workspace: {
                bibox: { top: 208, left: 328 },
                components: {},
                offset: { top: 0, left: 0 },
                scale: 1,
            },

            height: document.body.clientHeight,
            width: document.body.clientWidth,
        }
        var logic = {
            program: [
                {
                    type: 'start',
                    state: {
                        bic1: false,
                        bic2: false,
                        bic3: false,
                        bid2: false,
                        bif1: false,
                        bif2: false,
                        bif3: false,
                    },
                    bic1: false,
                    bic2: false,
                    bic3: false,
                    bid2: false,
                    bif1: false,
                    bif2: false,
                    bif3: false,
                    bid3: false,
                    bid1: false,
                    bmp3: false,
                },
            ],
            end: { type: 'end', state: 'repeat' },
            insertState: false,

            offset: { left: 0, top: 0 },
            scale: 1,
            currentProgramGuide: 0,
            active: [-1, -1],
            bottomPanel: 'border',
        }

        props.selecteComponent(concept)
        props.assemblyComponent(assembly)
        props.logicComponent(logic)

        sessionStorage.setItem('AppDetails', null)
        sessionStorage.removeItem('SelectedComp')
    }, [])

    useEffect(() => {
        if (isRecoverData.recoveryDataPresent === true) {
            let condProps = {
                show: isRecoverData.recoveryDataPresent,
                text: 'Would you like to recover your previous program?',
                optionsToSelect: [
                    {
                        text: 'YES',
                        do: async () => {
                            retriveRecoveryData(isRecoverData.link)
                            history.push(
                                isRecoverData.link
                                // getUrl[
                                //     connectedDevice == 'Ace'
                                //         ? deviceVersion.startsWith('1')
                                //             ? 'PeeCeeBeta'
                                //             : 'PeeCeeAlpha'
                                //         : connectedDevice
                                // ]
                            )
                        },
                    },
                    {
                        text: 'NO',
                        do: () => {
                            history.push(
                                isRecoverData.link
                                // getUrl[
                                //     connectedDevice == 'Ace'
                                //         ? deviceVersion.startsWith('1')
                                //             ? 'PeeCeeBeta'
                                //             : 'PeeCeeAlpha'
                                //         : connectedDevice
                                // ]
                            )
                            sessionStorage.removeItem('PET')
                            sessionStorage.removeItem('SelectedStatus')
                            sessionStorage.removeItem('EndSwitch')
                        },
                    },
                ],
            }
            console.log(condProps)
            setCondProps(condProps)
        }
        if (isRetrieveData === true) {
            let condProps = {
                show: isRetrieveData,
                text: isText,
                handleDialogModalClose: closeModal,
                showCloseBtn: true,
            }
            setCondProps(condProps)
        }
        if (
            isRecoverData.recoveryDataPresent === false &&
            isRetrieveData === false
        ) {
            let condProps = {
                show: false,
            }
            setCondProps(condProps)
        }
    }, [isRecoverData, isRetrieveData])

    const handleNewProject = (link) => {
        sessionStorage.setItem('isSave', JSON.stringify(true))
        let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))

        if (link === '/code/project/action/select') {
            programMode = 'actionBase'
        }

        if (
            localStorage.getItem('recoveryData') &&
            programMode in recoveryData &&
            connectedDevice in recoveryData[programMode] &&
            version in recoveryData[programMode][connectedDevice]
        ) {
            setIsRecoverData({ recoveryDataPresent: true, link: link })
        } else {
            history.push(link)
        }
    }

    const retriveRecoveryData = (link) => {
        try {
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            if (link === '/code/project/action/select') {
                let createActionState =
                    recoveryData['actionBase'][connectedDevice][version]
                        .createActionState
                sessionStorage.setItem(
                    'createActionState',
                    JSON.stringify(createActionState)
                )
            } else {
                recoveryData =
                    recoveryData[programMode][connectedDevice][version]

                for (let [key, value] of Object.entries(recoveryData)) {
                    sessionStorage.setItem(key, value)
                }
                props.selecteInternalAcessories(
                    JSON.parse(recoveryData.concept).internalaccessories
                )
                props.selecteComponent(JSON.parse(recoveryData.concept))
                props.assemblyComponent(JSON.parse(recoveryData.assembly))
                props.logicComponent(JSON.parse(recoveryData.logic))
            }
        } catch (e) {
            localStorage.removeItem('recoveryData')
        }
    }

    const openProject = (event, loadContentType, followUpURL) => {
        sessionStorage.setItem('isSave', JSON.stringify(true))
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        let file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const sessionData = JSON.parse(evt.target.result)
            const currDevice = sessionStorage.getItem('connectedDevice')
            const currMode = sessionStorage.getItem('programMode')
            if ('connectedDevice' in sessionData) {
                if (currDevice != sessionData.connectedDevice) {
                    setText(
                        `The Selected file is for ${sessionData.connectedDevice} not supported, please select the correct file for ${currMode}.`
                    )
                    setRetrieveData(true)
                    event.target.value = ''
                    return
                }
            } else {
                setText(
                    `The Selected file is for different device, please select the correct file for ${currMode}.`
                )
                setRetrieveData(true)
                event.target.value = ''
                return
            }

            console.log(connectedDevice, loadContentType)
            sessionStorage.setItem('isSavedData', true)
            if (['Roboki', 'Ace'].includes(connectedDevice)) {
                const currVersion = sessionStorage.getItem('deviceVersion')
                // const version = sessionStorage.getItem('deviceVersion') || null
                let sessionDataVersion = ''
                if ('deviceVersion' in sessionData) {
                    sessionDataVersion = sessionData.deviceVersion
                } else if ('PCVersion' in sessionData) {
                    if (sessionData.PCVersion !== '0.0.0') {
                        sessionDataVersion = sessionData.PCVersion
                    } else {
                        sessionDataVersion = sessionData.zingVersion
                    }
                } else {
                    sessionDataVersion = currVersion
                }

                if (
                    currVersion.slice(0, 3) ===
                        sessionDataVersion.slice(0, 3) &&
                    currMode === sessionData.programMode
                ) {
                    sessionStorage.setItem('deviceVersion', sessionDataVersion)
                    Object.entries(sessionData).map((value) => {
                        if (retrieveAssemblyData.includes(value[0])) {
                            sessionStorage.setItem(value[0], value[1])
                            if (value[0] === 'concept') {
                                props.selecteComponent(JSON.parse(value[1]))
                                props.selecteInternalAcessories(
                                    JSON.parse(value[1]).internalaccessories
                                )
                            }
                            if (value[0] === 'assembly') {
                                props.assemblyComponent(JSON.parse(value[1]))
                            }
                            if (value[0] === 'logic') {
                                props.logicComponent(JSON.parse(value[1]))
                            }
                        }
                    })
                    // if (version !== null && version?.startsWith('1')) {
                    //     props.history.push(
                    //         '/v1/selectScreen/InternalAccessories'
                    //     )
                    // } else {
                    //     props.history.push('/selectScreen/InternalAccessories')
                    // }
                    history.push(
                        getUrl[
                            connectedDevice == 'Ace'
                                ? deviceVersion.startsWith('1')
                                    ? 'PeeCeeBeta'
                                    : 'PeeCeeAlpha'
                                : connectedDevice
                        ]
                    )
                } else {
                    setText(
                        `The Selected file is for a different version of ${sessionData.connectedDevice}, please select the correct file for ${currMode} for your ${sessionData.connectedDevice}`
                    )
                    setRetrieveData(true)
                    event.target.value = ''
                }
            } else if (
                ['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice)
            ) {
                const currVersion = sessionStorage.getItem('deviceVersion')
                const currMode = sessionStorage.getItem('programMode')

                if (loadContentType === 'Open Action') {
                    if (sessionData.connectedDevice != connectedDevice) {
                        setText(
                            `The Selected file is for ${sessionData.connectedDevice}, please select the correct file for ${connectedDevice} `
                        )
                        setRetrieveData(true)
                    } else if (
                        currVersion.slice(0, 3) !==
                        sessionData.deviceVersion.slice(0, 3)
                    ) {
                        setText(
                            `The Selected file is for a different version of ${connectedDevice}, please select the correct file for ${connectedDevice} `
                        )
                        setRetrieveData(true)
                    } else {
                        Object.entries(sessionData).map((value) => {
                            sessionStorage.setItem(value[0], value[1])
                        })
                        history.push('/code/project/action/select')
                    }
                } else if (loadContentType === 'Open Project') {
                    if (
                        currVersion.slice(0, 3) ===
                            sessionData.deviceVersion.slice(0, 3) &&
                        currMode === sessionData.programMode
                    ) {
                        //update redux
                        Object.entries(sessionData).map((value) => {
                            if (retrieveAssemblyData.includes(value[0])) {
                                sessionStorage.setItem(value[0], value[1])
                                if (value[0] === 'concept') {
                                    props.selecteComponent(JSON.parse(value[1]))
                                    props.selecteInternalAcessories(
                                        JSON.parse(value[1]).internalaccessories
                                    )
                                }
                                if (value[0] === 'assembly') {
                                    props.assemblyComponent(
                                        JSON.parse(value[1])
                                    )
                                }
                                if (value[0] === 'logic') {
                                    props.logicComponent(JSON.parse(value[1]))
                                }
                            }
                        })
                        history.push('/code/InternalAccessories')
                        window.location.reload()
                    } else {
                        setText(
                            `The Selected file is for a different version of ${sessionData.connectedDevice}, please select the correct file for ${currMode} for your ${sessionData.connectedDevice}`
                        )
                        setRetrieveData(true)
                        event.target.value = ''
                    }
                }
            }
        }
        reader.readAsText(file)
    }

    const closeModal = () => {
        setRetrieveData(false)
        let condProps = {
            show: false,
        }
        setCondProps(condProps)
    }

    //in open the file, dialog model shows option generat
    const dialogModelOption = () => {
        let optionArr = []
        if (props.webSerial.isConnected) {
            optionArr.push({
                text: connectedDevice === 'Hexapod' ? 'crawl-e' : 'Zing',
                do: () => {
                    setShowActionModal(false)
                    setRetrieveActions(true)
                },
            })
        }

        optionArr.push({
            text: 'Local',
            do: () => {
                document.getElementById(`file-input-3`).click()
                setShowActionModal(false)
            },
        })
        return optionArr
    }
    const clearActionFromSession = () => {
        console.log('clearing action data')
        sessionStorage.removeItem('createActionState')
    }

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderPrgImage('zingProjectH'),
            renderPrgImage('zingProjectH1'),
            renderPrgImage('zingProjectH2'),
            renderPrgImage('zingProjectH3'),
            renderPrgImage('zingProjectH4'),
            renderPrgImage('SelectionPrgf1'),
            renderPrgImage('SelectionPrgf2'),
            renderPrgImage('SelectionPrgf3'),

            // internal, external,assembly,login screen

            renderPrgImage('saveBtnActive'),
            renderPrgImage('usbOFF'),
            renderPrgImage('usbON'),
            renderPrgImage('saveBtnInActive'),
            renderPrgImage('helpBtnActive'),
            renderPrgImage('helpBtnInActive'),
            renderPrgImage('selectbar'),
            renderPrgImage('backBtn'),
            renderPrgImage('nextBtn'),

            renderPrgImage('distancesensorsActive'),
            renderPrgImage('distancesensorsInActive'),
            renderPrgImage('gesturesensorActive'),
            renderPrgImage('gesturesensorInActive'),
            renderPrgImage('lightsensorActive'),
            renderPrgImage('lightsensorInActive'),
            renderPrgImage('colorsensorActive'),
            renderPrgImage('colorsensorInActive'),
            renderPrgImage('micActive'),
            renderPrgImage('micInActive'),
            renderPrgImage('batteryActive'),
            renderPrgImage('batteryInActive'),
            renderPrgImage('tempActive'),
            renderPrgImage('tempInActive'),
            renderPrgImage('touch0Active'),
            renderPrgImage('touch0InActive'),
            renderPrgImage('touch1Active'),
            renderPrgImage('touch2Active'),
            renderPrgImage('touch3Active'),
            renderPrgImage('touch1InActive'),
            renderPrgImage('touch2InActive'),
            renderPrgImage('touch3InActive'),
            renderPrgImage('lefteyeActive'),
            renderPrgImage('lefteyeInActive'),
            renderPrgImage('righteyeActive'),
            renderPrgImage('righteyeInActive'),
            renderPrgImage('smile1Active'),
            renderPrgImage('smile1InActive'),
            renderPrgImage('smile2Active'),
            renderPrgImage('smile2InActive'),
            renderPrgImage('smile3Active'),
            renderPrgImage('smile3InActive'),
            renderPrgImage('smile4Active'),
            renderPrgImage('smile4InActive'),
            renderPrgImage('readPCActive'),
            renderPrgImage('readPCInActive'),
            renderPrgImage('buzzerActive'),
            renderPrgImage('buzzerInActive'),
            renderPrgImage('accelerometerActive'),
            renderPrgImage('accelerometerInActive'),

            renderPrgImage('devicePc'),
            renderPrgImage('PcinternalMicInActive'),
            renderPrgImage('PcinternalMicActive'),
            renderPrgImage('PcinternalBuzzerInActive'),
            renderPrgImage('PcinternalBuzzerActive'),
            renderPrgImage('PcinternalTouchpadsInActive'),
            renderPrgImage('PcinternalTouchpadsActive'),

            renderPrgImage('PcinternalEYEInActive'),
            renderPrgImage('PcinternalEYEActive'),
            renderPrgImage('PcinternalTeethActive'),
            renderPrgImage('PcinternalTeethInActive'),
            renderPrgImage('Pcinternal4in1Active'),
            renderPrgImage('Pcinternal4in1InActive'),

            renderPrgImage('popupcardType'),
            renderPrgImage('tempInActive'),
            renderPrgImage('tempActive'),

            // zing
            renderImage('activeBattery'),
            renderImage('activeUltrasonic'),
            renderImage('activeAccelerometerX'),
            renderImage('activeAccelerometerY'),
            renderImage('activeAccelerometerZ'),
            renderImage('activeGyroX'),
            renderImage('activeGyroY'),
            renderImage('activeGyroZ'),
            renderImage('activeLeftRGB'),
            renderImage('activeRightRBG'),
            renderImage('activeMp3'),
            renderImage('activeIndividual'),
            renderImage('activeActionGroup'),
            renderImage('activeHeadServo'),
            renderPrgImage('HumanoidWithPC'),

            renderCompImg('pot'),
            renderCompImg('play_shield'),
            renderCompImg('play_shield_v1_top'),
            renderCompImg('play_shield_top'),
            renderCompImg('dc_motor'),
            renderCompImg('dynamex_motor'),
            renderCompImg('geared_motor'),
            renderCompImg('mini_geared_motor'),
            renderCompImg('distance_sensor'),
            renderCompImg('dual_switch'),
            renderCompImg('joystick'),
            renderCompImg('laser'),
            renderCompImg('led'),
            renderCompImg('servo_motor'),
            renderCompImg('servo_motor_270'),
            renderCompImg('servo_motor_360'),
            renderCompImg('light_sensor'),
            renderCompImg('tact_switch'),
            renderCompImg('touch_sensor'),
            renderCompImg('ultrasonic_sensor'),
            renderCompImg('led_1c'),
            renderCompImg('tact_switch_2c'),
            renderCompImg('RGB'),
            renderCompImg('OLED'),
            renderCompImg('mp3'),
            renderCompImg('dip_switch'),
            renderCompImg('linear_pot'),
            renderCompImg('humidity'),
            renderCompImg('metal_detector'),
            renderCompImg('extender'),
            renderCompImg('temperature_sensor'),
            renderCompImg('gas'),
            renderCompImg('rain_sensor'),
            renderCompImg('pc_motor_driver'),
            renderCompImg('dual_splitter'),
            // helpScreen
            renderPrgImage('InternalPrgf1'),
            renderPrgImage('InternalPrgf2'),
            renderPrgImage('InternalPrgf3'),
            renderPrgImage('InternalPrgf4'),
            renderPrgImage('Pcv1SelectH1'),
            renderPrgImage('Pcv1SelectH2'),
            renderPrgImage('ExternalPrgf2'),
            renderPrgImage('ExternalPrgf3'),
            renderPrgImage('assemblyH'),
            renderPrgImage('Pcv1AssemblyH'),
            renderPrgImage('AssemblyPrgf1'),
            renderPrgImage('assemblyH2'),
            renderPrgImage('Pcv1AssemblyH2'),
            renderPrgImage('AssemblyPrgf2'),
            renderPrgImage('assemblyH3'),
            renderPrgImage('Pcv1AssemblyH3'),
            renderPrgImage('AssemblyPrgf3'),
            renderPrgImage('AssemblyPrgf4'),
            renderPrgImage('LogicPrgf1'),
            renderPrgImage('LogicPrgf2'),
            renderPrgImage('LogicPrgf3'),
            renderPrgImage('LogicPrgf4'),
            renderPrgImage('LogicPrgf5'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    console.log(MENU_OPTIONS)
    return (
        <>
            <DialogModal {...isCondProps} />
            <DialogModal
                show={showActionModal}
                text="Open files from"
                showCloseBtn={true}
                handleDialogModalClose={() => setShowActionModal(false)}
                optionsToSelect={dialogModelOption()}
            />
            <ViewActions
                viewPopUp={retrieveActions}
                setViewPopUp={setRetrieveActions}
            />
            <MainHeader
                help_btnPosition="right"
                helper={
                    ['Humanoid', 'Hexapod'].includes(connectedDevice) ? (
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <ZingPrgmSelection />
                        </div>
                    ) : (
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <PrgmSelection />
                        </div>
                    )
                }
                goBack={() => {
                    setSlideDirection(true)
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/code')
                }}
                isGoBack={true}
            />
            <img
                alt=""
                src={renderPrgImage('programmenucard')}
                style={{
                    position: 'absolute',
                    top: '8.5%',
                    left: '12%',
                    transform: 'translate(-50%,-50%)',
                    height: '22%',
                }}
            />
            <p
                style={{
                    position: 'absolute',
                    top: '7%',
                    left: '11.5%',
                    transform: 'translate(-50%,-50%)',
                    color: 'white',
                    fontSize: '18px',
                }}
            >
                Code
            </p>

            <div style={{ height: '80%', position: 'relative' }}>
                {' '}
                <div style={container}>
                    {MENU_OPTIONS &&
                        MENU_OPTIONS.map((option, index) => (
                            <div
                                key={index}
                                style={
                                    ['Hexapod', 'Klaw'].includes(
                                        connectedDevice
                                    ) &&
                                    ['Open Action', 'New Action'].includes(
                                        option.name
                                    )
                                        ? {
                                              width: '19rem',
                                              height: '15rem',
                                              //   opacity: 0.3,
                                          }
                                        : { width: '19rem', height: '15rem' }
                                }
                            >
                                {option.name === 'Open Project' ||
                                option.name === 'Open Action' ? (
                                    <>
                                        <img
                                            alt=""
                                            src={renderPrgImage(option.imgSrc)}
                                            style={{
                                                ...option.imgStyle,
                                                opacity: '1',
                                                // option.name ===
                                                //     'Open Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                        />
                                        <p
                                            style={{
                                                ...menuText,
                                                opacity: '1',
                                                // option.name ===
                                                //     'Open Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                        >
                                            {option.name}
                                        </p>
                                        <div
                                            style={{
                                                width: '50%',
                                                height: '54%',
                                                position: 'relative',
                                                top: '-16rem',
                                                left: '5rem',
                                                cursor: 'pointer',
                                                // option.name ===
                                                //     'Open Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? 'default'
                                                //     : 'pointer',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     [
                                                //         'Hexapod',
                                                //         'Humanoid',
                                                //     ].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //     ['1'].includes(
                                                //         deviceVersion[0]
                                                //     ) &&
                                                //     option.name == 'Open Action'
                                                // ) {
                                                //     return
                                                // }
                                                if (
                                                    [
                                                        'Humanoid',
                                                        'Hexapod',
                                                    ].includes(
                                                        connectedDevice
                                                    ) &&
                                                    ['1', '2'].includes(
                                                        deviceVersion[0]
                                                    ) &&
                                                    option.name == 'Open Action'
                                                ) {
                                                    setShowActionModal(true)
                                                    return
                                                }

                                                document
                                                    .getElementById(
                                                        `file-input-${index}`
                                                    )
                                                    .click()
                                            }}
                                        ></div>
                                        {
                                            <input
                                                id={`file-input-${index}`}
                                                type="file"
                                                accept=".pld"
                                                hidden
                                                onInput={(event) => {
                                                    openProject(
                                                        event,
                                                        option.name,
                                                        option.link
                                                    )
                                                }}
                                            />
                                        }
                                    </>
                                ) : (
                                    <>
                                        <img
                                            alt=""
                                            src={renderPrgImage(option.imgSrc)}
                                            style={{
                                                ...option.imgStyle,
                                                opacity: '1',
                                                // option.name ===
                                                //     'New Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                        />
                                        <p
                                            style={{
                                                ...menuText,
                                                opacity: '1',
                                                // option.name ===
                                                //     'New Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     [
                                                //         'Hexapod',
                                                //         'Humanoid',
                                                //     ].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //     ['1'].includes(
                                                //         deviceVersion[0]
                                                //     ) &&
                                                //     option.name == 'New Action'
                                                // ) {
                                                //     return
                                                // }

                                                sessionStorage.removeItem(
                                                    'slideDirection'
                                                )
                                                if (
                                                    [
                                                        'New Project',
                                                        'New Action',
                                                    ].includes(option.name)
                                                )
                                                    handleNewProject(
                                                        option.link
                                                    )
                                                else history.push(option.link)
                                            }}
                                        >
                                            {option.name}
                                        </p>
                                        <div
                                            style={{
                                                width: '50%',
                                                height: '54%',
                                                position: 'relative',
                                                top: '-16rem',
                                                left: '5rem',
                                                cursor: 'pointer',
                                                // option.name ===
                                                //     'New Action' &&
                                                // [
                                                //     'Hexapod',
                                                //     'Humanoid',
                                                // ].includes(
                                                //     connectedDevice
                                                // ) &&
                                                // ['1'].includes(
                                                //     deviceVersion[0]
                                                // )
                                                //     ? 'default'
                                                //     : 'pointer',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     [
                                                //         'Hexapod',
                                                //         'Humanoid',
                                                //     ].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //     ['1'].includes(
                                                //         deviceVersion[0]
                                                //     ) &&
                                                //     option.name == 'New Action'
                                                // ) {
                                                //     return
                                                // }
                                                sessionStorage.removeItem(
                                                    'slideDirection'
                                                )
                                                if (
                                                    [
                                                        'New Project',
                                                        'New Action',
                                                    ].includes(option.name)
                                                )
                                                    handleNewProject(
                                                        option.link
                                                    )
                                                else history.push(option.link)
                                            }}
                                        ></div>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        device: state.device,
        webSerial: state.webSerial,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'COMPONENT', payload: data })
        },
        selecteInternalAcessories: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },

        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_RESET', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
