// import React, { useState, useEffect, useLayoutEffect } from 'react'
import stylee from './Graph.module.css'
import renderPrgImage from '../../../source/programImg'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import { useHistory } from 'react-router'
import { GraphPlotStorage } from './GraphPlotStorage'
import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { portsData } from './GraphPlotStorage'
import closeBtn from '../../../Assets/img/close.png'
import { graphPlotStorage } from '../../../redux/actions'
import { sensorsData } from './GraphsensorsPortData'

// import { GraphPlotStorage } from './GraphPlotStorage'

function Graph(props) {
    let history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')

    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    console.log('props', props)
    let states = props.indexData.graphPlotStorage.internalaccessories
    let disabledSensorsList =
        props.indexData.graphPlotStorage.internalaccessories.portsData
            .filter((port) => port.ports.find((el) => el.isActive))
            .map((obj) => obj.key)

    let deviceName = 'PlayComputer'
    switch (connectedDevice) {
        case 'Ace':
            deviceName = 'PlayComputer'
            break
        case 'Humanoid':
            deviceName = 'Zing'
            break
        case 'Hexapod':
            deviceName = 'Crawl-e'
            break
        case 'Klaw':
            deviceName = 'Klaw-b'
            break
        default:
            deviceName = connectedDevice
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    const backBtn = () => {
        history.push('/Selection')
        sessionStorage.setItem('GraphData', false)
        let graphPlotStorage = JSON.parse(
            sessionStorage.getItem('graphPlotStorage')
        )
        console.log('graphPlotStorage', graphPlotStorage)
        graphPlotStorage.time = { h: 0, m: 0, s: 0, ms: 0 }
        props.graphPlotStorage(graphPlotStorage)
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)

        props.DeselectedComponent({
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensorRed: false,
                isColorSensorGreen: false,
                isColorSensorBlue: false,
            },

            isMic: false,
            isTemperature: false,
            isBattery: false,
            isAccelerometerX: false,
            isAccelerometerY: false,
            isAccelerometerZ: false,
            isGyroX: false,
            isGyroY: false,
            isGyroZ: false,
            isUltrasonic: false,
            isSwitchOne: false,
            isSwitchTwo: false,

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,
            isTouchThree: false,
        })

        props.DeselectedComponent({
            portsData: [
                {
                    key: 'A',
                    ports: [
                        {
                            portName: 'A1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#E25432',
                        },
                        {
                            portName: 'A2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#B0D745',
                        },
                    ],
                },

                {
                    key: 'B',
                    ports: [
                        {
                            portName: 'B1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#23DE93',
                        },
                        {
                            portName: 'B2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#3A3A3A',
                        },
                    ],
                },

                {
                    key: 'C',
                    ports: [
                        {
                            portName: 'C1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#0ACEF7',
                        },
                        {
                            portName: 'C2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#5061A8',
                        },
                    ],
                },

                {
                    key: 'D',
                    ports: [
                        {
                            portName: 'D1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#0E3C11',
                        },
                        {
                            portName: 'D2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#DD1624',
                        },
                    ],
                },

                {
                    key: 'E',
                    ports: [
                        {
                            portName: 'E1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#F79B47',
                        },
                        {
                            portName: 'E2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#F666D0',
                        },
                    ],
                },

                {
                    key: 'F',
                    ports: [
                        {
                            portName: 'F1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#3D5B53',
                        },
                        {
                            portName: 'F2',
                            isActive: false,
                            isDigital: false,
                            isColor: 'black',
                        },
                    ],
                },
            ],
        })
        portsData[0] = {
            key: 'A',
            ports: [
                {
                    portName: 'A1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#E25432',
                },
                {
                    portName: 'A2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#B0D745',
                },
            ],
        }

        portsData[1] = {
            key: 'B',
            ports: [
                {
                    portName: 'B1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#23DE93',
                },
                {
                    portName: 'B2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3A3A3A',
                },
            ],
        }

        portsData[2] = {
            key: 'C',
            ports: [
                {
                    portName: 'C1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0ACEF7',
                },
                {
                    portName: 'C2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#5061A8',
                },
            ],
        }

        portsData[3] = {
            key: 'D',
            ports: [
                {
                    portName: 'D1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0E3C11',
                },
                {
                    portName: 'D2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#DD1624',
                },
            ],
        }

        portsData[4] = {
            key: 'E',
            ports: [
                {
                    portName: 'E1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F79B47',
                },
                {
                    portName: 'E2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F666D0',
                },
            ],
        }

        portsData[5] = {
            key: 'F',
            ports: [
                {
                    portName: 'F1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3D5B53',
                },
                {
                    portName: 'F2',
                    isActive: false,
                    isDigital: false,
                    isColor: 'black',
                },
            ],
        }
    }
    const nextBtn = () => {
        history.push('/graphPorts')
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
    }

    const [ishelpButton, sethelpButton] = useState(false)

    const sensorsDataObj =
        sensorsData[connectedDevice]['V' + deviceVersion.slice(0, 1)]
    const reduxInternalaccessories =
        props.indexData.graphPlotStorage.internalaccessories

    //for Accelerometer and gyrometer is select or not defined by isAccelerometer its set hre
    useEffect(() => {
        const setisAccelerometer = () => {
            if (
                [
                    reduxInternalaccessories.isAccelerometerX,
                    reduxInternalaccessories.isAccelerometerY,
                    reduxInternalaccessories.isAccelerometerZ,
                    reduxInternalaccessories.isGyroX,
                    reduxInternalaccessories.isGyroY,
                    reduxInternalaccessories.isGyroZ,
                ].includes(true)
            ) {
                props.graphPlotStorage({ isAccelerometer: true })
            } else {
                props.graphPlotStorage({ isAccelerometer: false })
            }
        }
        setisAccelerometer()
    }, [
        reduxInternalaccessories.isAccelerometerX,
        reduxInternalaccessories.isAccelerometerY,
        reduxInternalaccessories.isAccelerometerZ,
        reduxInternalaccessories.isGyroX,
        reduxInternalaccessories.isGyroY,
        reduxInternalaccessories.isGyroZ,
    ])

    const handleEventsClick = async (name) => {
        switch (name) {
            case 'mic': {
                if (reduxInternalaccessories.isMic) {
                    console.log('false')
                    GraphPlotStorage.isMic = false
                    props.graphPlotStorage({ isMic: false })
                    setToastMsg('Mic Disabled')
                    setToastActive(true)
                    disableToast()
                } else {
                    console.log('true')
                    GraphPlotStorage.isMic = true
                    props.graphPlotStorage({ isMic: true })
                    setToastMsg('Mic Enabled')
                    setToastActive(true)
                    disableToast()
                }
                break
            }

            case 'temperature': {
                if (reduxInternalaccessories.isTemperature) {
                    GraphPlotStorage.isTemperature = false
                    props.graphPlotStorage({ isTemperature: false })
                    // setTemperature(false)
                    // sessionStorage.setItem('isTemperature', 'false')
                    setToastMsg('Temperature Sensor Disabled')
                    setToastActive(true)
                    disableToast()
                } else {
                    GraphPlotStorage.isTemperature = true
                    props.graphPlotStorage({ isTemperature: true })
                    // setTemperature(true)
                    // sessionStorage.setItem('isTemperature', 'true')
                    setToastMsg('Temperature Sensor Enabled')
                    setToastActive(true)
                    disableToast()
                }
                break
            }

            case 'battery': {
                if (reduxInternalaccessories.isBattery) {
                    GraphPlotStorage.isBattery = false
                    props.graphPlotStorage({ isBattery: false })
                    // setBattery(false)
                    // sessionStorage.setItem('isBattery', 'false')
                    setToastMsg('Battery Disabled')
                    setToastActive(true)
                    disableToast()
                } else {
                    GraphPlotStorage.isBattery = true

                    props.graphPlotStorage({ isBattery: true })

                    // setBattery(true)
                    // sessionStorage.setItem('isBattery', 'true')
                    setToastMsg('Battery Enabled')
                    setToastActive(true)
                    disableToast()
                }
                break
            }

            case 'touch0': {
                if (reduxInternalaccessories.isTouchZero) {
                    GraphPlotStorage.isTouchZero = false
                    props.graphPlotStorage({ isTouchZero: false })
                    // setTouchZero(false)
                    setToastMsg('Touch0 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'false')
                } else {
                    if (
                        connectedDevice == 'Ace' &&
                        !deviceVersion?.startsWith('1') &&
                        disabledSensorsList.includes('A')
                    ) {
                        setToastMsg('Disable Port A to enable isTouchZero')
                        setToastActive(true)
                        disableToast()
                        return
                    }
                    GraphPlotStorage.isTouchZero = true
                    props.graphPlotStorage({ isTouchZero: true })
                    // setTouchZero(true)
                    setToastMsg('Touch0 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'true')
                }
                break
            }

            case 'touch1': {
                if (reduxInternalaccessories.isTouchOne) {
                    GraphPlotStorage.isTouchOne = false
                    props.graphPlotStorage({ isTouchOne: false })
                    // setTouchOne(false)
                    setToastMsg('Touch1 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchOne', 'false')
                } else {
                    if (
                        connectedDevice == 'Ace' &&
                        !deviceVersion?.startsWith('1') &&
                        disabledSensorsList.includes('B')
                    ) {
                        setToastMsg('Disable Port B to enable isTouchOne')
                        setToastActive(true)
                        disableToast()
                        return
                    }
                    GraphPlotStorage.isTouchOne = true
                    props.graphPlotStorage({ isTouchOne: true })
                    // setTouchOne(true)
                    setToastMsg('Touch1 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchOne', 'true')
                }
                break
            }

            case 'touch2': {
                if (reduxInternalaccessories.isTouchTwo) {
                    GraphPlotStorage.isTouchTwo = false
                    props.graphPlotStorage({ isTouchTwo: false })
                    // setTouchTwo(false)
                    setToastMsg('Touch2 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchTwo', 'false')
                } else {
                    if (
                        connectedDevice == 'Ace' &&
                        !deviceVersion?.startsWith('1') &&
                        disabledSensorsList.includes('C')
                    ) {
                        setToastMsg('Disable Port C to enable isTouchTwo')
                        setToastActive(true)
                        disableToast()
                        return
                    }
                    GraphPlotStorage.isTouchTwo = true
                    props.graphPlotStorage({ isTouchTwo: true })
                    // setTouchTwo(true)
                    setToastMsg('Touch2 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchTwo', 'true')
                }
                break
            }

            case 'touch3': {
                if (reduxInternalaccessories.isTouchThree) {
                    GraphPlotStorage.isTouchThree = false
                    props.graphPlotStorage({ isTouchThree: false })
                    // setTouchThree(false)
                    setToastMsg('Touch3 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchThree', 'false')
                } else {
                    GraphPlotStorage.isTouchThree = true
                    props.graphPlotStorage({ isTouchThree: true })
                    // setTouchThree(true)
                    setToastMsg('Touch3 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchThree', 'true')
                }
                break
            }

            case 'accelerometerX': {
                if (reduxInternalaccessories.isAccelerometerX) {
                    GraphPlotStorage.isAccelerometerX = false
                    props.graphPlotStorage({ isAccelerometerX: false })
                    // setAccelerometerX(false)
                    setToastMsg('AccelerometerX Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerX', 'false')
                } else {
                    GraphPlotStorage.isAccelerometerX = true
                    props.graphPlotStorage({ isAccelerometerX: true })
                    // setAccelerometerX(true)
                    setToastMsg('AccelerometerX Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerX', 'true')
                }
                break
            }

            case 'accelerometerY': {
                if (reduxInternalaccessories.isAccelerometerY) {
                    GraphPlotStorage.isAccelerometerY = false
                    props.graphPlotStorage({ isAccelerometerY: false })
                    // setAccelerometerY(false)
                    setToastMsg('AccelerometerY Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerY', 'false')
                } else {
                    GraphPlotStorage.isAccelerometerY = true
                    props.graphPlotStorage({ isAccelerometerY: true })
                    // setAccelerometerY(true)
                    setToastMsg('AccelerometerY Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerY', 'true')
                }
                break
            }

            case 'accelerometerZ': {
                if (reduxInternalaccessories.isAccelerometerZ) {
                    GraphPlotStorage.isAccelerometerZ = false
                    props.graphPlotStorage({ isAccelerometerZ: false })
                    // setAccelerometerZ(false)
                    setToastMsg('AccelerometerZ Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerZ', 'false')
                } else {
                    GraphPlotStorage.isAccelerometerZ = true
                    props.graphPlotStorage({ isAccelerometerZ: true })
                    // setAccelerometerZ(true)
                    setToastMsg('AccelerometerZ Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isAccelerometerZ', 'true')
                }
                break
            }

            case 'GyroX': {
                if (reduxInternalaccessories.isGyroX) {
                    GraphPlotStorage.isGyroX = false
                    props.graphPlotStorage({ isGyroX: false })
                    // setGyroX(false)
                    setToastMsg('GyroX Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroX', 'false')
                } else {
                    GraphPlotStorage.isGyroX = true
                    props.graphPlotStorage({ isGyroX: true })
                    // setGyroX(true)
                    setToastMsg('GyroX Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroX', 'true')
                }
                break
            }

            case 'GyroY': {
                if (reduxInternalaccessories.isGyroY) {
                    GraphPlotStorage.isGyroY = false
                    props.graphPlotStorage({ isGyroY: false })
                    // setGyroY(false)
                    setToastMsg('GyroY Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroY', 'false')
                } else {
                    GraphPlotStorage.isGyroY = true
                    props.graphPlotStorage({ isGyroY: true })
                    // setGyroY(true)
                    setToastMsg('GyroY Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroY', 'true')
                }
                break
            }

            case 'GyroZ': {
                if (reduxInternalaccessories.isGyroZ) {
                    GraphPlotStorage.isGyroZ = false
                    props.graphPlotStorage({ isGyroZ: false })
                    // setGyroZ(false)
                    setToastMsg('GyroZ Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroZ', 'false')
                } else {
                    GraphPlotStorage.isGyroZ = true
                    props.graphPlotStorage({ isGyroZ: true })
                    // setGyroZ(true)
                    setToastMsg('GyroZ Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isGyroZ', 'true')
                }
                break
            }

            case 'ultrasonic': {
                if (reduxInternalaccessories.isUltrasonic) {
                    // console.log('false')
                    GraphPlotStorage.isUltrasonic = false
                    props.graphPlotStorage({ isUltrasonic: false })
                    // sessionStorage.setItem('isMic', 'false')
                    // setUltrasonic(false)
                    setToastMsg('Ultrasonic Disabled')
                    setToastActive(true)
                    disableToast()
                } else {
                    // console.log('true')
                    GraphPlotStorage.isUltrasonic = true
                    props.graphPlotStorage({ isUltrasonic: true })
                    // sessionStorage.setItem('isMic', 'true')
                    // setUltrasonic(true)
                    setToastMsg('Ultrasonic Enabled')
                    setToastActive(true)
                    disableToast()
                }
                break
            }
            case 'lightsensor': {
                if (reduxInternalaccessories.Four_in_one_sensor.isLightSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    props.graphPlotStorage({ isLightSensor: false })
                    sessionStorage.setItem('isLightsensor', 'false')

                    // setLightSensor(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: true,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                        isColorSensor: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensor = false
                    sessionStorage.setItem('isLightsensor', 'true')

                    // setColorSensor(false)
                    // setDistanceSensors(false)
                    // setGestureSensor(false)
                    // setLightSensor(true)
                }
                break
            }

            case 'distancesensor': {
                if (
                    reduxInternalaccessories.Four_in_one_sensor
                        .isDistanceSensors
                ) {
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    props.graphPlotStorage({ isDistanceSensors: false })
                    sessionStorage.setItem('isDistancesensor', 'false')
                    // setDistanceSensors(false)
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: true,
                        isGestureSensor: false,
                        isColorSensor: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = true
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isColorSensor = false
                    sessionStorage.setItem('isDistancesensor', 'true')
                    // setColorSensor(false)
                    // setDistanceSensors(true)
                    // setGestureSensor(false)
                    // setLightSensor(false)
                }
                break
            }

            case 'gesturesensor': {
                if (
                    reduxInternalaccessories.Four_in_one_sensor.isGestureSensor
                ) {
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    props.graphPlotStorage({ isGestureSensor: false })
                    // setGestureSensor(false)
                    sessionStorage.setItem('isGesturesensor', 'false')
                } else {
                    props.graphPlotStorage({
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: true,
                        isColorSensor: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = true
                    GraphPlotStorage.Four_in_one_sensor.isColorSensor = false
                    sessionStorage.setItem('isGesturesensor', 'true')
                    // setColorSensor(false)

                    // setDistanceSensors(false)
                    // setGestureSensor(true)
                    // setLightSensor(false)
                }
                break
            }

            case 'colorsensor': {
                if (reduxInternalaccessories.Four_in_one_sensor.isColorSensor) {
                    GraphPlotStorage.Four_in_one_sensor.isColorSensor = false
                    props.graphPlotStorage({ isColorSensor: false })
                    // setColorSensor(false)
                    sessionStorage.setItem('isColorsensor', 'false')
                } else {
                    props.graphPlotStorage({
                        isColorSensor: true,
                        isLightSensor: false,
                        isDistanceSensors: false,
                        isGestureSensor: false,
                    })
                    GraphPlotStorage.Four_in_one_sensor.isColorSensor = true

                    GraphPlotStorage.Four_in_one_sensor.isLightSensor = false
                    GraphPlotStorage.Four_in_one_sensor.isDistanceSensors = false
                    GraphPlotStorage.Four_in_one_sensor.isGestureSensor = false
                    sessionStorage.setItem('isColorsensor', 'true')
                    // setDistanceSensors(false)
                    // setGestureSensor(false)
                    // setLightSensor(false)
                    // setColorSensor(true)
                }
                break
            }
            case 'switch1': {
                if (reduxInternalaccessories.isSwitchOne) {
                    GraphPlotStorage.isSwitchOne = false
                    props.graphPlotStorage({ isSwitchOne: false })
                    // setTouchZero(false)
                    setToastMsg('Switch1 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'false')
                } else {
                    GraphPlotStorage.isSwitchOne = true
                    props.graphPlotStorage({ isSwitchOne: true })
                    // setTouchZero(true)
                    setToastMsg('Switch1 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'true')
                }
                break
            }
            case 'switch2': {
                if (reduxInternalaccessories.isSwitchTwo) {
                    GraphPlotStorage.isSwitchTwo = false
                    props.graphPlotStorage({ isSwitchTwo: false })
                    // setTouchZero(false)
                    setToastMsg('Switch2 Disabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'false')
                } else {
                    GraphPlotStorage.isSwitchTwo = true
                    props.graphPlotStorage({ isSwitchTwo: true })
                    // setTouchZero(true)
                    setToastMsg('Switch2 Enabled')
                    setToastActive(true)
                    disableToast()
                    // sessionStorage.setItem('isTouchZero', 'true')
                }
                break
            }
        }
    }

    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    return (
        <>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <div style={{ height: '20%' }}>
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Sensors',
                                'Select Ports',
                                'Set Time',
                                'Set Graph',
                                'Plot',
                            ],
                            selected: 0,
                        }}
                        showSave={false}
                        showHelp={true}
                        graph={true}
                        handleHelp={handleClickhelpbtn}
                    />
                </div>

                <div className={stylee.Sensor_Body}>
                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                left: '35%',
                                top: '90%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('backBtn')}
                            onClick={backBtn}
                        />
                    </div>

                    <div
                        style={
                            connectedDevice == 'Roboki'
                                ? {
                                      width: '60%',
                                      gridTemplateColumns: '50% 50%',
                                      gridTemplateRows: '100%',
                                      height: '20%',
                                      top: '25%',
                                  }
                                : { width: '60%' }
                        }
                        // className={stylee.Sensors_Container}
                        className={`${
                            slideDirection === 'true' ? 'slide-right' : ''
                        } ${stylee.Sensors_Container}`}
                    >
                        {Object.keys(sensorsDataObj).map(
                            (eachSensorData, ind) => {
                                //take from redux the button is enable or not
                                let isActiveImgRender = false
                                if (
                                    [
                                        'colorsensor',
                                        'lightsensor',
                                        'gesturesensor',
                                        'distancesensor',
                                    ].includes(eachSensorData)
                                ) {
                                    isActiveImgRender =
                                        props.indexData.graphPlotStorage
                                            .internalaccessories
                                            .Four_in_one_sensor[
                                            sensorsDataObj[eachSensorData]
                                                .reduxName
                                        ]
                                } else {
                                    isActiveImgRender =
                                        props.indexData.graphPlotStorage
                                            .internalaccessories[
                                            sensorsDataObj[eachSensorData]
                                                .reduxName
                                        ]
                                }

                                //take the ecch button background image url
                                let backImgUrl = ''
                                if (isActiveImgRender) {
                                    backImgUrl = `url(${renderPrgImage(
                                        sensorsDataObj[eachSensorData]
                                            .activeImgURL
                                    )})`
                                } else {
                                    backImgUrl = `url(${renderPrgImage(
                                        sensorsDataObj[eachSensorData]
                                            .inactiveImgURL
                                    )})`
                                }
                                return (
                                    <>
                                        {
                                            <div
                                                key={ind}
                                                className={stylee.Container}
                                                style={{
                                                    backgroundImage: backImgUrl,
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundSize: '100% 100%',
                                                }}
                                            >
                                                <div
                                                    style={
                                                        connectedDevice ==
                                                        'Roboki'
                                                            ? { width: '50%' }
                                                            : {}
                                                    }
                                                    onClick={() =>
                                                        handleEventsClick(
                                                            eachSensorData
                                                        )
                                                    }
                                                    className={
                                                        stylee.sub_Container
                                                    }
                                                >
                                                    <p>
                                                        {
                                                            sensorsDataObj[
                                                                eachSensorData
                                                            ].sensors
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        }
                                    </>
                                )
                            }
                        )}

                        {/* Toast msg */}
                        <div
                            className={`toast-msg toast-msg-graph
        ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                            ref={toastRef}
                            style={{
                                right: '60vh',
                                top: '90vh',
                                position: 'fixed',
                            }}
                        >
                            {toastMsg}
                        </div>
                    </div>

                    {/* next button */}
                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                right: '15%',
                                top: '90%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('nextBtn')}
                            onClick={nextBtn}
                        />
                    </div>
                </div>

                {ishelpButton === true ? (
                    <div className={stylee.helpScreen}>
                        <div
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '47%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                                width: '65vw',
                            }}
                        >
                            <img
                                src={renderPrgImage('graphSensorH')}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div
                            className={stylee.help}
                            style={{
                                width: '17%',
                                top: '49.5%',
                                left: '51.3%',
                            }}
                        >
                            <p className={stylee.help_text}>
                                Select the Internal Accessories of the{' '}
                                {deviceName} to display the sensor values on the
                                Plotting screen
                            </p>
                        </div>

                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '18%',
                                left: '81%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        indexData: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        graphPlotStorage: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'GRAPH_PLOTSTORAGE', payload: data })
        },

        // webSerialConnect: (data) => {
        //     dispatch(webSerialConnect(data))
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Graph)
